import React from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"

const styles = createStyles({
  heading: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.56rem",
    textAlign: "center",
    color: "#898989",
    maxWidth: 710,
  },
  title: {
    fontWeight: 800,
    fontSize: "2.33rem",
    lineHeight: "3.28rem",
    color: "#000000",
  },
  coloredTitle: {
    color: "#FF7900",
  },
  description: {
    marginTop: 20,
  },
})

export interface Props {
  title: string
  coloredWords: number
  description?: string
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const Heading = ({
  classes,
  title,
  coloredWords,
  description,
}: PropsAndStyles) => {
  const titleWords = title.split(" ")
  const coloredTitle = titleWords.slice(0, coloredWords).join(" ").concat(" ")
  const whiteTitle = titleWords.slice(coloredWords).join(" ")

  return (
    <div className={classes.heading}>
      <div className={classes.title}>
        <span className={classes.coloredTitle}>{coloredTitle}</span>
        <span>{whiteTitle}</span>
      </div>

      {description && <div className={classes.description}>{description}</div>}
    </div>
  )
}

export default withStyles(styles)(Heading)
