import React from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"

import CursorIcon from "../images/icons/cursor.svg"

const styles = createStyles({
  button: {
    position: "relative",
    fontWeight: 600,
    fontSize: "0.83rem",
    textTransform: "uppercase",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background:
      "linear-gradient(270deg, #FFBE01 0%, #FFB401 32.29%, #FF9401 65.62%, #FF7D01 100%)",
    height: 44,
    borderRadius: 30,
    padding: "18.5px 50px 12px 50px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    textDecoration: "none",
    color: "#212121",
    whiteSpace: "nowrap",
  },
  cursor: {
    position: "absolute",
    bottom: -16,
    right: 0,
  },
})

export interface Props {
  text: string
  background?: string
  color?: string
  cursor?: boolean
  href?: string
  blank?: boolean
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const CallToAction = ({
  classes,
  text,
  background,
  color,
  cursor,
  href,
  blank,
}: PropsAndStyles) => (
  <a
    className={classes.button}
    style={{ background, color }}
    href={href}
    target={blank ? "_blank" : "_self"}
  >
    {text}
    {cursor && <CursorIcon className={classes.cursor} />}
  </a>
)

export default withStyles(styles)(CallToAction)
